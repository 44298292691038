<div class="tag-list-item-wrapper">
  @if (avatarInfos) {
    <iu-avatar-ui [avatarInfos]="avatarInfos"></iu-avatar-ui>
  }
  @if (!noMatch) {
    <span class="tag-list-item-value">
      {{ value }}
    </span>
  } @else {
    <span class="tag-list-item-value"> {{ 'inputMention.tagListItem.noMatch' | translate }} {{ value }} </span>
  }
</div>
