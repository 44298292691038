import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import {
  APP_INITIALIZER,
  ApplicationConfig,
  ErrorHandler,
  importProvidersFrom,
  Injectable,
  Signal,
} from '@angular/core';
import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig, HammerModule } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { HttpInterceptorService } from '@compiere-ws/http-interceptor.service';
import { IuErrorHandler } from '@compiere-ws/iu-error-handler.service';
import { FilterComponent } from '@iupics-components/standard/grid/filters/apiz-filter/filter.component';
import { AppConfig } from '@iupics-config/app.config.service';
import { KeybindConfigService } from '@iupics-config/keybind.config.service';
import {
  COMPONENT_FILTERS_REGISTRY,
  DEFAULT_COMPONENT_FILTERS_REGISTRY,
  GridTranslations,
  mergeTranslationsWithDefault,
  TRANSLATIONS,
} from '@iupics/apiz-grid';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { environment } from 'environments/environment';
import 'hammerjs';
import * as Hammer from 'hammerjs';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { MessageService } from 'primeng/api';
import routes from './app.routes';

//#region Keycloak
function initializeKeycloak(config: AppConfig, keycloak: KeycloakService) {
  return () =>
    new Promise(async (resolve) => {
      await config.loadEnvVariable();
      const result = await keycloak.init({
        config: environment.config.backend.auth,
        initOptions: {
          onLoad: 'login-required',
          checkLoginIframe: environment?.isDeployed ?? true,
        },
      });
      resolve(result);
    });
}
//#endregion

//#region TranslateService
// AoT requires an exported function for factories
function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, 'assets/i18n/', '.json');
}
//#endregion

//#region HammerJS
@Injectable()
class MyHammerConfig extends HammerGestureConfig {
  overrides = <any>{
    // override hammerjs default configuration
    swipe: { direction: Hammer.DIRECTION_HORIZONTAL },
    pinch: {
      enable: false,
    },
    rotate: {
      enable: false,
    },
    press: {
      time: 500,
    },
  };
  options = <any>{
    behavior: {
      userSelect: 'auto',
    },
  };
}
//#endregion

//#region Keybinding
function initializeKeybinding(keybindConfig: KeybindConfigService) {
  return () => keybindConfig.load();
}
//#endregion

//#region Utilities
function clearConsole() {
  return () => console.clear();
}
//#endregion

//#region Apiz-grid Translations
function setupApizGridTranslations(): Signal<GridTranslations> {
  return mergeTranslationsWithDefault({
    data: {
      loading: 'gridTools.loadingOoo',
      no_data: 'gridTools.noRowsToShow',
      error: 'gridTools.noRowsToShow',
    },
    grid: {
      pin: 'gridTools.pinLeft',
      unpin: 'gridTools.noPin',
    },
    pagination: {
      all: 'gridTools.pagination.all',
      auto: 'gridTools.pagination.auto',
      firstPage: 'gridTools.pagination.firstPage',
      lastPage: 'gridTools.pagination.lastPage',
      lineSelected: 'gridTools.pagination.lineSelected',
      more: 'gridTools.pagination.more',
      nextPage: 'gridTools.pagination.nextPage',
      of: 'gridTools.pagination.of',
      page: 'gridTools.pagination.page',
      previousPage: 'gridTools.pagination.previousPage',
      rowsPerPage: 'gridTools.pagination.rowsPerPage',
      to: 'gridTools.pagination.to',
    },
    panel: {
      manage: 'gridTools.manage',
      apply: 'gridTools.applyFilter',
      close: 'gridTools.panel.close',
      reset: 'gridTools.panel.reset',
      aggregations: {
        title: 'gridTools.aggregations.title',
        function: 'gridTools.aggregations.function',
        column: 'gridTools.aggregations.column',
        add: 'gridTools.aggregations.add',
      },
      filters: {
        title: 'gridTools.filters.title',
        operators: {
          isNull: 'universalFilter.filterLabels.isNull',
          isNotNull: 'universalFilter.filterLabels.isNotNull',
          between: 'universalFilter.filterLabels.between',
          contains: 'universalFilter.filterLabels.contains',
          notContains: 'universalFilter.filterLabels.notContains',
          startsWith: 'universalFilter.filterLabels.startsWith',
          endsWith: 'universalFilter.filterLabels.endsWith',
          and: 'universalFilter.and',
          biggerThan: 'gridTools.greaterThan',
          biggerThanOrEqual: 'gridTools.greaterThanOrEqual',
          lessThan: 'gridTools.lessThan',
          lessThanOrEqual: 'gridTools.lessThanOrEqual',
          equals: 'gridTools.equals',
          notEqual: 'gridTools.notEquals',
          or: 'gridTools.or',
        },
      },
      groups: {
        title: 'gridTools.groups.title',
        column: 'gridTools.groups.column',
      },
      settings: {
        title: 'gridTools.settings.title',
        columns: 'gridTools.settings.columns',
        searchPlaceholder: 'gridTools.settings.search',
      },
      sorts: {
        title: 'gridTools.sorts.title',
        column: 'gridTools.sorts.column',
      },
    },
  });
}
//#endregion

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(
      // Angular
      BrowserModule,
      HammerModule,
      KeycloakAngularModule,
      // Projects Modules
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient],
        },
      }),
      environment.production ? ServiceWorkerModule.register('ngsw-worker.js') : []
    ),
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
    provideRouter(routes),
    AppConfig,
    KeybindConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: clearConsole,
      multi: true,
      deps: [],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [AppConfig, KeycloakService],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeybinding,
      deps: [KeybindConfigService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: IuErrorHandler,
    },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig,
    },
    MessageService,
    {
      provide: COMPONENT_FILTERS_REGISTRY,
      useValue: {
        ...DEFAULT_COMPONENT_FILTERS_REGISTRY,
        text: FilterComponent,
        number: FilterComponent,
        date: FilterComponent,
        time: FilterComponent,
        set: FilterComponent,
      },
    },
    {
      provide: TRANSLATIONS,
      useFactory: setupApizGridTranslations,
    },
  ],
};
