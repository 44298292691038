<ng-template #vcrFilter />
@if (filterToDisplay.operator.operator.isRange) {
  @switch (filterToDisplay.column.columnInfo.filterType) {
    @case ('number') {
      <iu-input-number-ui
        [isStandalone]="true"
        [fieldValue]="filterToDisplay.filterTo"
        (fieldValueModified)="setFilterTo($event)" />
    }
    @case ('date') {
      <iu-calendar-ui
        [hasTodayBtn]="true"
        [isStandalone]="true"
        [data]="{
          needTime: filterToDisplay.column.columnInfo.fieldEntity.field.AD_Reference_ID === 16,
        }"
        [fieldValue]="
          filterToDisplay.filterTo | toDate: filterToDisplay.column.columnInfo.fieldEntity.field.AD_Reference_ID === 16
        "
        [calendarConfig]="filterToDisplay.configsTo?.calendarConfig || { todayMode: false }"
        (calendarConfigChange)="onCalendarConfigChange($event, true)"
        (fieldValueModified)="setFilterTo($event)" />
    }
    @case ('time') {
      <iu-input-time-ui
        [isStandalone]="true"
        [fieldValue]="filterToDisplay.filterTo | toDate: true"
        [calendarConfig]="filterToDisplay.configsTo?.calendarConfig || { todayMode: false }"
        (calendarConfigChange)="onCalendarConfigChange($event, true)"
        (fieldValueModified)="setFilterTo($event)" />
    }
  }
}
